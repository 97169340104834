import fixtures from './fixtures.json';
import minimum_monthly_payment_fixtures from './minimum-monthly-payment-fixtures.json';

// Domain
import { RetirementInvestorGoalCalculatorDto }
  from '../../../domain/dtos/retirement-investor-goal-calculator-dto';
import { RetirementInvestorGoalCalculatorEntity }
  from '../../../domain/entities/retirement-investor-goal-calculator-entity';
import {
  RetirementGoalMinimumMonthlyPensionCalculationEntity,
} from '@/modules/flagship/retirement-investor-goal-calculator/domain/entities/retirement-goal-minimum-monthly-pension-calculation-entity';
import { RetirementInvestorGoalCalculatorRepository }
  from '../../../domain/repositories/retirement-investor-goal-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RetirementInvestorGoalCalculatorDummyRepository implements
  RetirementInvestorGoalCalculatorRepository {
  get_retirement_fund_calculation(retirement_fund_calculation_dto:
    RetirementInvestorGoalCalculatorDto): Promise<RetirementInvestorGoalCalculatorEntity> {
    return Promise.resolve(fixtures);
  }

  calculateMinimumMonthlyPension():
    Promise<RetirementGoalMinimumMonthlyPensionCalculationEntity> {
    return Promise.resolve(minimum_monthly_payment_fixtures);
  }
}
