import createInvestorGoalWizard from './create-investor-goal-wizard.json';
import goalTypeSelector from './goal-type-selector.json';
import createdSuccessfully from './created-successfully.json';
import linkSuccessfully from './link-successfully.json';
import emergencyFund from './emergency-fund';
import retirementFund from './retirement-fund';
import custom from './custom';
import bucket from './bucket';
import unallocatedBalance from './unallocated-balance';

export default {
  'create-goal': {
    ...createInvestorGoalWizard,
    ...goalTypeSelector,
    ...emergencyFund,
    ...retirementFund,
    ...custom,
    ...bucket,
    ...createdSuccessfully,
    ...linkSuccessfully,
    ...unallocatedBalance,
  },
};
