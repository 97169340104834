import flagshipProfilingModalInit from './flagship-profiling-modal-init.json';
import flagshipProfilingStepRetirementFund from './flagship-profiling-step-retirement-fund.json';
import flagshipProfilingStepEconomicDependents from './flagship-profiling-step-economic-dependents.json';
import flagshipProfilingStepEmploymentSituation from './flagship-profiling-step-employment-situation.json';
import flagshipProfilingStepEmergencyFund from './flagship-profiling-step-emergency-fund.json';
import flagshipProfilingStepGoals from './flagship-profiling-step-goals.json';
import flagshipProfilingStepFinancialHabits from './flagship-profiling-step-financial-habits.json';
import flagshipProfilingStepProcess from './flagship-profiling-step-process.json';
import flagshipProfilingProjection from './flagship-profiling-projection.json';
import flagshipProfilingExitPoll from './flagship-profiling-exit-poll.json';
import flagshipProfilingSowosPocket from './flagship-profiling-sowos-pocket.json';
import flagshipProfilingSowosWealth from './flagship-profiling-sowos-wealth.json';
import flagshipProfilingStepIntroduction from './flagship-profiling-step-introduction.json';
import flagshipProfilingVideo from './flagship-profiling-video.json';
import flagshipProfilingStepIntroductionVideo from './flagship-profiling-step-introduction-video.json';
import flagshipProfilingStepGetReady from './flagship-profiling-step-get-ready.json';
import flagshipProfilingStepBudgetVideo from './flagship-profiling-step-budget-video.json';
import flagshipProfilingStepEmergencyFundVideo from './flagship-profiling-step-emergency-fund-video.json';
import flagshipProfilingStepProjectionVideo from './flagship-profiling-step-projection-video.json';
import flagshipProfilingYouAlreadyHaveYourContractedProduct from './flagship-profiling-you-already-have-your-contracted-product.json';
import flagshipProfilingIncompleteFlow from './flagship-profiling-incomplete-flow.json';
import flagshipProfilingStepFinancialFreedom from './flagship-profiling-step-financial-freedom.json';
import flagshipProfilingStepStartGoals from './flagship-profiling-step-start-goals.json';
import flagshipProfilingChooseFlowGoals from './flagship-profiling-choose-create-goals.json';

export default {
  'flagship-profiling': {
    profiling: 'Perfilamiento',
    steps: [
      'Tus dependientes',
      'Tu situación laboral',
      'Tu presupuesto',
      'Tus metas financieras',
      'Tu libertad financiera',
    ],
    errors: {
      get_investor_profile: 'Fallo la carga de tu perfil de investionista.',
      update_last_step_flow_one: 'Ocurrió un error al guardar tus datos',
    },
    ...flagshipProfilingModalInit,
    ...flagshipProfilingStepRetirementFund,
    ...flagshipProfilingStepEconomicDependents,
    ...flagshipProfilingStepEmploymentSituation,
    ...flagshipProfilingStepEmergencyFund,
    ...flagshipProfilingStepGoals,
    ...flagshipProfilingStepFinancialHabits,
    ...flagshipProfilingStepProcess,
    ...flagshipProfilingProjection,
    ...flagshipProfilingExitPoll,
    ...flagshipProfilingSowosPocket,
    ...flagshipProfilingSowosWealth,
    ...flagshipProfilingStepIntroduction,
    ...flagshipProfilingVideo,
    ...flagshipProfilingStepIntroductionVideo,
    ...flagshipProfilingStepGetReady,
    ...flagshipProfilingStepBudgetVideo,
    ...flagshipProfilingStepEmergencyFundVideo,
    ...flagshipProfilingStepProjectionVideo,
    ...flagshipProfilingYouAlreadyHaveYourContractedProduct,
    ...flagshipProfilingIncompleteFlow,
    ...flagshipProfilingStepFinancialFreedom,
    ...flagshipProfilingStepStartGoals,
    ...flagshipProfilingChooseFlowGoals,
  },
};
