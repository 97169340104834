import TYPES from '@/types';

// Application
import { GetAllianzAccountQuery, GetAllianzAccountIsOnCancellationProcessQuery }
  from '@/modules/my-investment/allianz/allianz-account/application/queries';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetGoalDefinitionStepQuery
  from '@/modules/flagship/goal-definition-step/application/queries/get-goal-definition-step-query';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';

// Domain
import {
  HireDataDto,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/dtos/hire-data-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';

export default class AllianzDashboardViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_allianz_account_is_on_cancellation_process!: GetAllianzAccountIsOnCancellationProcessQuery;

  @Inject(TYPES.GET_GOAL_DEFINITION_STEP_QUERY)
  private readonly get_goal_definition_step_query!: GetGoalDefinitionStepQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.allianz-dashboard';

  wealth_policy_in_process_of_cancellation = false;

  show_more_investment_alternatives_banner = false;

  show_more_investment_alternatives_dialog = false;

  show_successfully = false;

  show_more_investment_alternatives_steps_dialog = false;

  goal_setting_flow_completed = false;

  investment_profiling_completed = false;

  show_flagship_profiling_modal = false;

  show_flagship_goals_modal = false;

  show_define_goals_wizard = false;

  show_flagship_goals_wizard_modal = false;

  show_flagship_link_wealth_wizard = false;

  show_requests_dialog = false;

  associated_product_id = '';

  associated_contract = true;

  hire_moderate = {
    show: false,
    show_successfully: false,
    investor_goal_id: '',
    goal_name: '',
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  validateAccount = async (): Promise<boolean> => {
    try {
      const account = await this.get_allianz_account_query.execute(
        { customer_id: this.customer_id },
      );
      return account.was_issued;
    } catch (error) {
      return false;
    }
  }

  validateIfWealthPolicyInProcessOfCancellation = async () => {
    try {
      const { is_on_cancellation_process } = await this
        .get_allianz_account_is_on_cancellation_process.execute();
      this.wealth_policy_in_process_of_cancellation = is_on_cancellation_process;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.policy_in_process_of_cancellation'));
    }
  }

  validateIfHasLinkedGoals = async () => {
    try {
      const associated_products = await this.get_investment_products_query.execute();
      const wealth_product = associated_products.find(
        (product) => product.name === 'sowos_wealth',
      );
      if (wealth_product) {
        this.associated_product_id = wealth_product.id;
        const search_by_customer_dto = {
          reload: true,
          associated_product_id: wealth_product.id,
          is_active: true,
        };
        const linked_goals = await this.get_search_by_customer_query
          .execute(search_by_customer_dto);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      if (investor_profile) {
        this.goal_setting_flow_completed = investor_profile.goals_completed;
        this.investment_profiling_completed = investor_profile.is_completed;
        if (!this.goal_setting_flow_completed || !this.investment_profiling_completed) {
          this.show_more_investment_alternatives_banner = true;
        } else {
          await this.validateIfHasLinkedGoals();
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  loadGoalDefinitionStep = async () => {
    try {
      const { main_item } = await this.get_goal_definition_step_query.execute(false);
      this.show_flagship_goals_wizard_modal = main_item !== 'informative' && main_item !== undefined;
      this.show_flagship_goals_modal = main_item === 'informative' || main_item === undefined;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_goal_definition_step'));
    }
  };

  initializeGoalsIncomplete = async () => {
    await this.loadGoalDefinitionStep();
  }

  initializeGoalDefinitionWizard = async () => {
    await this.initializeGoalsIncomplete();
  }

  showMoreInvestmentAlternativesStepsDialog = () => {
    this.show_more_investment_alternatives_steps_dialog = true;
  }

  hireModerate = (hire_data: HireDataDto) => {
    this.show_successfully = true;
    this.hire_moderate.investor_goal_id = hire_data.investor_goal_id;
    this.hire_moderate.goal_name = hire_data.goal_name;
  }

  closeHireModerate = () => {
    window.location.reload();
  }

  showMoreInvestmentAlternativesDialog = () => {
    this.router.navigate('goals-dashboard');
  }

  showDefineGoalsModal = async () => {
    if (!this.investment_profiling_completed) {
      this.show_flagship_profiling_modal = true;
    }

    if (this.investment_profiling_completed && !this.goal_setting_flow_completed) {
      await this.initializeGoalDefinitionWizard();
    }

    if (this.investment_profiling_completed && this.goal_setting_flow_completed) {
      this.show_flagship_link_wealth_wizard = true;
    }
  }

  closeDefineGoalsModal = () => {
    this.show_flagship_goals_modal = false;
    this.show_flagship_goals_wizard_modal = true;
  }

  openDefineGoalsFlow = async () => {
    this.show_more_investment_alternatives_dialog = false;
    await this.showDefineGoalsModal();
  }

  acceptAccessMoreStrategySuccessfullyDialog = () => {
    window.location.href = '/goals-dashboard';
  }

  dispatchSetTitle = () => {
    const set_title_event = new CustomEvent('nav-bar.title', {
      detail: {
        title: [
          {
            label: 'SOWOS Wealth',
          },
        ],
        back_reference: '/',
      },
    });

    window.dispatchEvent(set_title_event);
  }

  showPendingRequests = () => {
    this.show_requests_dialog = true;
  }

  initialize = async () => {
    this.dispatchSetTitle();
    await this.validateIfWealthPolicyInProcessOfCancellation();
    await this.loadInvestorProfile();
  }
}
