import TYPES from '../../types';

// Domain
import { CustomGoalMaximumMonthsCalculationEntity } from '@/modules/flagship/custom-investor-goal-calculator/domain/entities/custom-goal-maximum-months-calculation-entity';
import { CustomInvestorGoalCalculatorRepository } from '@/modules/flagship/custom-investor-goal-calculator/domain/repositories/custom-investor-goal-calculator-repository';
import { CustomGoalMaximumMonthsCalculationDto } from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-months-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateCustomGoalMaximumMonthsQuery extends Query<
  Promise<CustomGoalMaximumMonthsCalculationEntity>
> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
  private readonly repository!: CustomInvestorGoalCalculatorRepository;

  internalExecute(
    payload: CustomGoalMaximumMonthsCalculationDto,
  ): Promise<CustomGoalMaximumMonthsCalculationEntity> {
    return this.repository.calculateMaximumInitialMonths(payload);
  }
}

export default CalculateCustomGoalMaximumMonthsQuery;
