






























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import GoalsDashboardViewModel from '@/vue-app/view-models/views/goals-dashboard-view-model';

@Component({
  name: 'GoalsDashboard',
  components: {
    ProgressCard: () => import('@/vue-app/components/goals-dashboard/ProgressCard.vue'),
    DefineYourGoalsCard: () => import('@/vue-app/components/goals-dashboard/DefineYourGoalsCard.vue'),
    DistributeUnassignedAmountCard: () => import('@/vue-app/components/goals-dashboard/DistributeUnassignedAmountCard.vue'),
    EmergencyFundCard: () => import('@/vue-app/components/goals-dashboard/EmergencyFundCard.vue'),
    RetirementFundCard: () => import('@/vue-app/components/goals-dashboard/RetirementFundCard.vue'),
    CustomInvestorGoalCard: () => import('@/vue-app/components/goals-dashboard/CustomInvestorGoalCard.vue'),
    LinkedEmergencyFundCard: () => import('@/vue-app/components/goals-dashboard/LinkedEmergencyFundCard.vue'),
    LinkedRetirementFundCard: () => import('@/vue-app/components/goals-dashboard/LinkedRetirementFundCard.vue'),
    LinkedCustomInvestorGoalCard: () => import('@/vue-app/components/goals-dashboard/LinkedCustomInvestorGoalCard.vue'),
    AddCustomInvestorGoalCard: () => import('@/vue-app/components/goals-dashboard/AddCustomInvestorGoalCard.vue'),
    ModifySwsModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/modify-sws-moderate/ModifySwsModerateDialog.vue'),
    LinkStrategyModerateWealthDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-goal/LinkStrategyModerateWealthDialog.vue'),
    StrategyModerateWealthDialog: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthDialog.vue'),
    StrategyModerateHiringFlowDialog: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyModerateHiringFlowDialog.vue'),
    StrategyModerateWealthExitPoll: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthExitPoll.vue'),
    ContractingStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/contracting-status-moderate/ContractingStatusModerateDialog.vue'),
    LinkStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-status-moderate/LinkStatusModerateDialog.vue'),
    SowosWealthCard: () => import('@/vue-app/components/my-investment-dashboard-v2/SowosWealthCard.vue'),
    SowosPocketCard: () => import('@/vue-app/components/my-investment-dashboard-v2/SowosPocketCard.vue'),
    FlagshipProfilingSowosPocket: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingSowosPocket.vue'),
    FlagshipProfilingSowosWealth: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingSowosWealth.vue'),
    FlagshipProfilingYouAlreadyHaveYourContractedProduct: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingYouAlreadyHaveYourContractedProduct.vue'),
    CreateInvestorGoalWizardModal: () => import('@/vue-app/components/goals-dashboard/create-goal/CreateInvestorGoalWizardModal.vue'),
    FlagshipGoalsModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsModal.vue'),
    FlagshipGoalsWizardModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardModal.vue'),
    InvestorGoalLinkedSuccessfully: () => import('@/vue-app/components/goals-dashboard/create-goal/InvestorGoalLinkedSuccessfully.vue'),
    InvestorGoalCreatedSuccessfully: () => import('@/vue-app/components/goals-dashboard/create-goal/InvestorGoalCreatedSuccessfully.vue'),
    BucketShortTimeCreatedSuccessfully: () => import('@/vue-app/components/goals-dashboard/create-goal/bucket/BucketShortTimeCreatedSuccessfully.vue'),
    AccessMoreStrategySuccessfullyDialog: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategySuccessfullyDialog.vue'),
    DistributeBalanceUnallocated: () => import('@/vue-app/components/goals-dashboard/create-goal/unallocated-balance/DistributeBalanceUnallocated.vue'),
    ContractSavings: () => import('@/vue-app/components/ContractSavings.vue'),
    ModalPrepareDocumentsWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/ModalPrepareDocumentsWealth.vue'),
  },
})
export default class GoalsDashboard extends Vue {
  goals_dashboard_view_model = Vue.observable(new GoalsDashboardViewModel());

  async created() {
    const set_title_event = new CustomEvent('nav-bar.title', {
      detail: {
        title: [
          {
            label: this.$i18n.t(`${this.goals_dashboard_view_model.i18n_namespace}.title`),
          },
        ],
      },
    });
    window.dispatchEvent(set_title_event);
    const query = { ...this.$route.query };
    let recommended_investment_product = '';
    let contract_investment_product = '';
    let show_create_goals = false;

    if (query) {
      recommended_investment_product = Object.prototype.hasOwnProperty
        .call(query, 'recommended_investment_product')
        ? query.recommended_investment_product.toString() : '';
      contract_investment_product = Object.prototype.hasOwnProperty
        .call(query, 'contract_investment_product')
        ? query.contract_investment_product.toString() : '';
      show_create_goals = Object.prototype.hasOwnProperty
        .call(query, 'create_goals');
    }
    await this.goals_dashboard_view_model.initialize(
      recommended_investment_product,
      contract_investment_product,
      show_create_goals,
    );
  }

  hideRecommendedInvestmentProduct() {
    if (this.$route?.query?.hasOwnProperty('recommended_investment_product')) {
      const query = { ...this.$route.query };
      delete query.recommended_investment_product;
      this.$router.replace({ query });
    }
  }
}
