// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';
import {
  ForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/forecast-calculation-dto';
import {
  ProjectionCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/projection-calculation-dto';
import {
  ProjectionCalculatedEntity,
} from '@/modules/flagship/retirement-forecast-calculation/domain/entities/projection-calculated-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RetirementForecastCalculationDummyRepository
implements RetirementForecastCalculationRepository {
  get(): Promise<ForecastCalculationDto> {
    return Promise.resolve(fixtures);
  }

  update(): Promise<void> {
    return Promise.resolve();
  }

  create(): Promise<ForecastCalculationDto> {
    return Promise.resolve(fixtures);
  }

  calculate_projection(payload: ProjectionCalculationDto): Promise<ProjectionCalculatedEntity> {
    return Promise.resolve(fixtures);
  }
}
