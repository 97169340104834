import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CustomInvestorGoalCalculatorDummyRepository
  from './infrastructure/repositories/custom-investor-goal-calculator-dummy-repository/custom-investor-goal-calculator-dummy-repository';
import CustomInvestorGoalCalculatorHttpRepository
  from './infrastructure/repositories/custom-investor-goal-calculator-http-repository';

// Application
import GetCustomInvestorGoalCalculatorQuery
  from './application/queries/get-custom-investor-goal-calculator-query';
import CalculateCustomGoalMaximumInitialAmountQuery
  from './application/queries/calculate-custom-goal-maximum-initial-amount-query';
import CalculateCustomGoalMinimumAccumulatedAmountQuery
  from './application/queries/calculate-custom-goal-minimum-accumulated-amount-query';
import CalculateCustomGoalMaximumMonthsQuery
  from './application/queries/calculate-custom-goal-maximum-months-query';

// Domain
import { CustomInvestorGoalCalculatorRepository }
  from './domain/repositories/custom-investor-goal-calculator-repository';

export default function setupCustomInvestorGoalCalculator(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomInvestorGoalCalculatorRepository>(TYPES
      .CUSTOM_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
    .to(withDummies
      ? CustomInvestorGoalCalculatorDummyRepository
      : CustomInvestorGoalCalculatorHttpRepository)
    .inSingletonScope();

  container.bind<GetCustomInvestorGoalCalculatorQuery>(TYPES
    .GET_CUSTOM_INVESTOR_GOAL_CALCULATOR_QUERY)
    .to(GetCustomInvestorGoalCalculatorQuery)
    .inSingletonScope();

  container.bind<CalculateCustomGoalMaximumInitialAmountQuery>(TYPES
    .CALCULATE_CUSTOM_GOAL_MAXIMUM_INITIAL_AMOUNT_QUERY)
    .to(CalculateCustomGoalMaximumInitialAmountQuery)
    .inSingletonScope();

  container.bind<CalculateCustomGoalMinimumAccumulatedAmountQuery>(TYPES
    .CALCULATE_CUSTOM_GOAL_MINIMUM_ACCUMULATED_AMOUNT_QUERY)
    .to(CalculateCustomGoalMinimumAccumulatedAmountQuery)
    .inSingletonScope();

  container.bind<CalculateCustomGoalMaximumMonthsQuery>(TYPES
    .CALCULATE_CUSTOM_GOAL_MAXIMUM_MONTHS_QUERY)
    .to(CalculateCustomGoalMaximumMonthsQuery)
    .inSingletonScope();
}
