// Domain
import {
  InvestorGoalRiskToleranceRepository,
} from '../../../domain/repositories/investor-goal-risk-tolerance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorGoalRiskToleranceDummyRepository
implements InvestorGoalRiskToleranceRepository {
  update(): Promise<void> {
    return Promise.resolve();
  }

  notify(): Promise<void> {
    return Promise.resolve();
  }
}
