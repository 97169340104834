import TYPES from '@/types';

// Domain
import {
  InvestorProfileOnEmploymentSituationRepository,
} from '@/modules/flagship/investor-profile/employment-situation/domain/repositories/investor-profile-on-employment-situation-repository';
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorProfileOnEmploymentSituationHttpRepository
implements InvestorProfileOnEmploymentSituationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-profile/employment-situation/{investor_profile_id}/`;

  update(investor_profile_entity: InvestorProfileEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investor_profile_id}', investor_profile_entity.id), 'PATCH', {
        employment_situation_id: investor_profile_entity.employment_situation_id,
        finance_related_job: investor_profile_entity.finance_related_job,
        level_of_education_id: investor_profile_entity.level_of_education_id,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
