import flagshipGoalsStepInit from './flagship-goals-step-init.json';
import flagShipGoalsStepShortGoal from './flagship-goals-step-short-goal.json';
import flagShipGoalsStepMediumGoal from './flagship-goals-step-medium-goal.json';
import flagShipGoalsStepLongGoal from './flagship-goals-step-long-goal.json';
import flagShipGoalsModal from './flagship-goals-modal.json';
import flagShipGoalsWizardModal from './flagship-goals-wizard-modal.json';
import flagShipGoalsWizardEmergencyFund from './flagship-goals-wizard-emergency-fund.json';
import flagShipGoalsWizardCustomizeFund from './flagship-goals-wizard-customize-fund.json';
import flagShipGoalsWizardPersonalGoal from './flagship-goals-wizard-personal-goal.json';
import flagShipGoalsWizardCustomizeGoal from './flagship-goals-wizard-customize-goal.json';
import flagshipGoalsWizardRetirementFund from './flagship-goals-wizard-retirement-fund.json';
import flagshipGoalsWizardRetirementFundSecond from './flagship-goals-wizard-retirement-fund-second.json';
import flagshipGoalsWizardAdjustGoals from './flagship-goals-wizard-adjust-goals.json';
import flagshipGoalsWizardProcess from './flagship-goals-wizard-process.json';
import flagshipGoalsWizardBeforeAdjustGoals from './flagship-goals-wizard-before-adjust-goals.json';
import createGoal from './create-goal';

export default {
  'flagship-goals': {
    ...flagShipGoalsModal,
    ...flagshipGoalsStepInit,
    ...flagShipGoalsStepShortGoal,
    ...flagShipGoalsStepMediumGoal,
    ...flagShipGoalsStepLongGoal,
    ...flagShipGoalsWizardModal,
    ...flagShipGoalsWizardEmergencyFund,
    ...flagShipGoalsWizardCustomizeFund,
    ...flagShipGoalsWizardPersonalGoal,
    ...flagShipGoalsWizardCustomizeGoal,
    ...flagshipGoalsWizardRetirementFund,
    ...flagshipGoalsWizardRetirementFundSecond,
    ...flagshipGoalsWizardAdjustGoals,
    ...flagshipGoalsWizardProcess,
    ...flagshipGoalsWizardBeforeAdjustGoals,
    ...createGoal,
  },
};
