import TYPES from '@/modules/shared/types';

// Domain
import {
  RetirementInvestorGoalCalculatorRepository,
} from '../../domain/repositories/retirement-investor-goal-calculator-repository';
import { RetirementInvestorGoalCalculatorDto }
  from '../../domain/dtos/retirement-investor-goal-calculator-dto';
import {
  RetirementGoalMinimumMonthlyPensionCalculationDto,
} from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-goal-minimum-monthly-pension-dto';
import { RetirementInvestorGoalCalculatorEntity }
  from '../../domain/entities/retirement-investor-goal-calculator-entity';
import {
  RetirementGoalMinimumMonthlyPensionCalculationEntity,
} from '@/modules/flagship/retirement-investor-goal-calculator/domain/entities/retirement-goal-minimum-monthly-pension-calculation-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class RetirementInvestorGoalCalculatorHttpRepository implements
  RetirementInvestorGoalCalculatorRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/retirement-investor-goal-calculator/`;

  get_retirement_fund_calculation(retirement_fund_calculation_dto:
    RetirementInvestorGoalCalculatorDto): Promise<RetirementInvestorGoalCalculatorEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}by-definition/`, 'POST', retirement_fund_calculation_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  calculateMinimumMonthlyPension(payload: RetirementGoalMinimumMonthlyPensionCalculationDto):
    Promise<RetirementGoalMinimumMonthlyPensionCalculationEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        `${this.endpoint}minimum-monthly-pension/`,
        'POST',
        payload,
      )
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
