import TYPES from '../../types';

// Domain
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';
import {
  ProjectionCalculatedEntity,
} from '@/modules/flagship/retirement-forecast-calculation/domain/entities/projection-calculated-entity';
import {
  ProjectionCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/projection-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CalculateProjectionCommand
  extends Command<ProjectionCalculationDto> {
  @Inject(TYPES.RETIREMENT_FORECAST_CALCULATION_REPOSITORY)
  private readonly repository!: RetirementForecastCalculationRepository;

  // eslint-disable-next-line max-len
  internalExecute(forecast_calculation_dto: ProjectionCalculationDto): Promise<ProjectionCalculatedEntity> {
    return this.repository.calculate_projection(forecast_calculation_dto);
  }
}
