export default class CreateEmergencyFundInvestorGoalState {
  is_loading = false;

  emergency = {
    months_of_monthly_incomes: 3,
    id: '',
    investment_goal_type_id: '',
    initial_amount: 0.0,
    desired_amount: 0.0,
    monthly_required_amount: 0.0,
    fixed_time_adjusted: 0.0,
    accumulated_amount: 0.0,
  }

  custom = {
    id: '',
    investment_goal_type_id: '',
    goal_name: '',
    initial_amount: 0.0,
    desired_amount: 0.0,
    monthly_required_amount: 0.0,
    fixed_time_adjusted: 0.0,
    accumulated_amount: 0.0,
    goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      created_at: '',
      updated_at: '',
    },
  }

  retirement = {
    id: '',
    investment_goal_type_id: '',
    issued_age: 0,
    retirement_age_adjusted: 50,
    retirement_range_adjusted: '',
    pension_range_adjusted: 0,
    monthly_pension_adjusted: '',
    monthly_required_amount: 0,
    initial_amount: 0,
    initial_amount_adjusted: 0,
    accumulated_amount: 0,
  }
}
