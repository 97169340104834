import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import LevelOfEducationDummyRepository
  from '@/modules/flagship/catalogs/level-of-education/infraestructure/repositories/level-of-education-dummy-repository/level-of-education-dummy-repository';
import LevelOfEducationHttpRepository
  from '@/modules/flagship/catalogs/level-of-education/infraestructure/repositories/level-of-education-http-repository';
import LevelOfEducationVueStateManager
  from '@/modules/flagship/catalogs/level-of-education/infraestructure/state/level-of-education-vue-state-manager';

// Application
import GetLevelOfEducationQuery
  from '@/modules/flagship/catalogs/level-of-education/application/queries/get-level-of-education-query';

// Domain
import {
  LevelOfEducationRepository,
} from '@/modules/flagship/catalogs/level-of-education/domain/repositories/level-of-education-repository';
import {
  LevelOfEducationStateManager,
} from '@/modules/flagship/catalogs/level-of-education/domain/state/level-of-education-state-manager';

export default function setupLevelOfEducation(
  container: Container,
) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<LevelOfEducationStateManager>(
      TYPES.LEVEL_OF_EDUCATION_STATE_MANAGER,
    )
    .to(LevelOfEducationVueStateManager)
    .inSingletonScope();

  container
    .bind<LevelOfEducationRepository>(
      TYPES.LEVEL_OF_EDUCATION_REPOSITORY,
    )
    .to(
      withDummies
        ? LevelOfEducationDummyRepository
        : LevelOfEducationHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetLevelOfEducationQuery>(
      TYPES.GET_LEVEL_OF_EDUCATION_QUERY,
    )
    .to(GetLevelOfEducationQuery)
    .inSingletonScope();
}
