import definePlan from './define-plan.json';
import contributions from './contributions.json';
import summary from './summary.json';

export default {
  custom: {
    ...definePlan,
    ...contributions,
    ...summary,
  },
};
