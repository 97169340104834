const BUCKET_URL = `${process.env.VUE_APP_S3_PUBLIC_URL}/investment/flagship/custom_investor_icons/`;

function getCustomGoalUpdatedAtTimeStamp(date: string) {
  return new Date(date).getTime();
}

export function getGoalIconPath(id: string, icon_name: string, updated_at: string) {
  let icon = '';
  if (icon_name) {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    icon = require(`@/assets/icons/custom-goal-icons/${icon_name}`);
    if (icon_name === 'icon-add.svg' && id) {
      icon = `${BUCKET_URL}${id}?${(updated_at) ? getCustomGoalUpdatedAtTimeStamp(updated_at) : ''}`;
    }
  }
  return icon;
}
