import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import EmergencyFundInvestorGoalVueStateManager
  from '@/modules/flagship/emergency-fund-investor-goal/infrastructure/state/emergency-fund-investor-goal-vue-state-manager';
import CreateEmergencyFundInvestorGoalVueStateManager
  from '@/modules/flagship/emergency-fund-investor-goal/infrastructure/state/create-emergency-fund-investor-goal-vue-state-manager';
import EmergencyFundInvestorGoalDummyRepository
  from '@/modules/flagship/emergency-fund-investor-goal/infrastructure/repositories/emergency-fund-investor-goal-dummy-repository/emergency-fund-investor-goal-dummy-repository';
import EmergencyFundInvestorGoalHttpRepository
  from '@/modules/flagship/emergency-fund-investor-goal/infrastructure/repositories/emergency-fund-investor-goal-http-repository';

// Domain
import {
  EmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/emergency-fund-investor-goal-state-manager';
import {
  CreateEmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/create-emergency-fund-investor-goal-state-manager';
import {
  EmergencyFundInvestorGoalRepository,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/repositories/emergency-fund-investor-goal-repository';

// Application
import GetEmergencyFundInvestorGoalQuery
  from '@/modules/flagship/emergency-fund-investor-goal/application/query/get-emergency-fund-investor-goal-query';
import UpdateEmergencyFundInvestorGoalCommand
  from '@/modules/flagship/emergency-fund-investor-goal/application/command/update-emergency-fund-investor-goal-command';
import UpdatePlanEmergencyFundInvestorGoalCommand
  from '@/modules/flagship/emergency-fund-investor-goal/application/command/update-plan-emergency-fund-investor-goal-command';

export default function setupEmergencyFundInvestorGoal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<EmergencyFundInvestorGoalStateManager>(TYPES.EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
    .to(EmergencyFundInvestorGoalVueStateManager)
    .inSingletonScope();

  container
    .bind<CreateEmergencyFundInvestorGoalStateManager>(
      TYPES.CREATE_EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER,
    )
    .to(CreateEmergencyFundInvestorGoalVueStateManager)
    .inSingletonScope();

  container
    .bind<EmergencyFundInvestorGoalRepository>(TYPES.EMERGENCY_FUND_INVESTOR_GOAL_REPOSITORY)
    .to(
      withDummies
        ? EmergencyFundInvestorGoalDummyRepository
        : EmergencyFundInvestorGoalHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetEmergencyFundInvestorGoalQuery>(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_QUERY)
    .to(GetEmergencyFundInvestorGoalQuery)
    .inSingletonScope();

  container
    .bind<UpdateEmergencyFundInvestorGoalCommand>(TYPES.UPDATE_EMERGENCY_FUND_INVESTOR_GOAL_COMMAND)
    .to(UpdateEmergencyFundInvestorGoalCommand)
    .inSingletonScope();

  container
    .bind<UpdatePlanEmergencyFundInvestorGoalCommand>(TYPES
      .UPDATE_PLAN_EMERGENCY_FUND_INVESTOR_GOAL_COMMAND)
    .to(UpdatePlanEmergencyFundInvestorGoalCommand)
    .inSingletonScope();
}
