import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CreateInvestorGoalVueStateManager from './infrastructure/state/create-investor-goal-vue-state-manager';

// Domain
import { CreateInvestorGoalStateManager } from './domain/state/create-investor-goal-state-manager';

export default function setupInvestorGoal(container: Container) {
  container
    .bind<CreateInvestorGoalStateManager>(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
    .to(CreateInvestorGoalVueStateManager)
    .inSingletonScope();
}
