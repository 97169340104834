import TYPES from '../../types';

// Domain
import { RetirementGoalMinimumMonthlyPensionCalculationEntity } from '@/modules/flagship/retirement-investor-goal-calculator/domain/entities/retirement-goal-minimum-monthly-pension-calculation-entity';
import { RetirementInvestorGoalCalculatorRepository } from '@/modules/flagship/retirement-investor-goal-calculator/domain/repositories/retirement-investor-goal-calculator-repository';
import { RetirementGoalMinimumMonthlyPensionCalculationDto } from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-goal-minimum-monthly-pension-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateRetirementGoalMinimumMonthlyPensionQuery extends Query<
  Promise<RetirementGoalMinimumMonthlyPensionCalculationEntity>
> {
  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
  private readonly repository!: RetirementInvestorGoalCalculatorRepository;

  internalExecute(
    payload: RetirementGoalMinimumMonthlyPensionCalculationDto,
  ): Promise<RetirementGoalMinimumMonthlyPensionCalculationEntity> {
    return this.repository.calculateMinimumMonthlyPension(payload);
  }
}

export default CalculateRetirementGoalMinimumMonthlyPensionQuery;
