// Domain
import {
  RetirementInvestorGoalDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-dto';

export default class RetirementInvestorGoalState {
  loaded = false;

  retirement_investor_goal: RetirementInvestorGoalDto = {
    id: '',
    investor_goal_id: '',
    investor_goal: {
      is_active: false,
      active_goal_amount: 0,
      investment_goal_type_id: '',
      investor_profile_id: '',
      monthly_required_amount: 0,
      associated_product_id: '',
      accumulated_amount: 0,
      final_investment_date: '',
      initial_investment_date: '',
      customer_id: '',
      id: '',
      created_at: '',
      updated_at: '',
      initial_amount: 0,
      associated_product: {
        interest_rate: 0,
        description: '',
        created_at: '',
        id: '',
        updated_at: '',
        investment_provider: {
          description: '',
          created_at: '',
          id: '',
          updated_at: '',
          label: '',
          name: '',
        },
        investment_provider_id: '',
        label: '',
        name: '',
      },
      investment_product_fund_types: {
        linked: [],
        available: [],
      },
      defined_by: '',
    },
    issued_age: 0,
    lifetime_expected: 0,
    has_afore: false,
    has_ppr: false,
    retirement_age_adjusted: 0,
    retirement_range_adjusted: 0,
    pension_range_adjusted: 0,
    monthly_pension_adjusted: 0,
    first_initial_investment_date: '',
    tracking_percent: 90.0011,
    created_at: '',
    updated_at: '',
  };
}
