import prepareDocuments from './prepare-documents.json';
import investmentInsurance from './investment-insurance.json';
import termsAndConditions from './terms_and_conditions.json';
import noticeOfPrivacy from './notice-of-privacy.json';
import kuspitAccount from './kuspit-account.json';

export default {
  'before-start': {
    continue: 'Continuar',
    back: 'Regresar',
    close: 'Cerrar',
    ...prepareDocuments,
    ...investmentInsurance,
    ...termsAndConditions,
    ...noticeOfPrivacy,
    ...kuspitAccount,
  },
};
