// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  LevelOfEducationEntity,
} from '@/modules/flagship/catalogs/level-of-education/domain/entities/level-of-education-entity';
import {
  LevelOfEducationRepository,
} from '@/modules/flagship/catalogs/level-of-education/domain/repositories/level-of-education-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class LevelOfEducationDummyRepository
implements LevelOfEducationRepository {
  execute(): Promise<Array<LevelOfEducationEntity>> {
    return Promise.resolve(fixtures);
  }
}
