import definePlan from './define-plan.json';
import video from './emergency-fund-video.json';
import contributions from './contributions.json';
import summary from './summary.json';

export default {
  'emergency-fund': {
    ...definePlan,
    ...video,
    ...contributions,
    ...summary,
  },
};
