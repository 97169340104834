import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RetirementForecastCalculationHttpRepository
  from '@/modules/flagship/retirement-forecast-calculation/infraestructure/repositories/retirement-forecast-calculation-http-repository';
import RetirementForecastCalculationDummyRepository
  from '@/modules/flagship/retirement-forecast-calculation/infraestructure/repositories/retirement-forecast-calculation-dummy-repository/retirement-forecast-calculation-dummy-repository';

// Application
import GetRetirementForecastCalculationQuery
  from '@/modules/flagship/retirement-forecast-calculation/application/queries/get-retirement-forecast-calculation-query';
import UpdateRetirementForecastCalculationCommand
  from '@/modules/flagship/retirement-forecast-calculation/application/commands/update-retirement-forecast-calculation-command';
import CreateRetirementForecastCalculationCommand
  from '@/modules/flagship/retirement-forecast-calculation/application/commands/create-retirement-forecast-calculation-command';
import CalculateProjectionCommand
  from '@/modules/flagship/retirement-forecast-calculation/application/commands/calculate-projection-command';

// Domain
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';

export default function setupRetirementForecastCalculation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<RetirementForecastCalculationRepository>(
      TYPES.RETIREMENT_FORECAST_CALCULATION_REPOSITORY,
    ).to(
      withDummies
        ? RetirementForecastCalculationDummyRepository
        : RetirementForecastCalculationHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetRetirementForecastCalculationQuery>(
      TYPES.GET_RETIREMENT_FORECAST_CALCULATION_QUERY,
    ).to(GetRetirementForecastCalculationQuery)
    .inSingletonScope();

  container
    .bind<UpdateRetirementForecastCalculationCommand>(
      TYPES.UPDATE_RETIREMENT_FORECAST_CALCULATION_COMMAND,
    ).to(UpdateRetirementForecastCalculationCommand)
    .inSingletonScope();

  container
    .bind<CreateRetirementForecastCalculationCommand>(
      TYPES.CREATE_RETIREMENT_FORECAST_CALCULATION_COMMAND,
    ).to(CreateRetirementForecastCalculationCommand)
    .inSingletonScope();

  container
    .bind<CalculateProjectionCommand>(
      TYPES.CALCULATE_PROJECTION_COMMAND,
    ).to(CalculateProjectionCommand)
    .inSingletonScope();
}
