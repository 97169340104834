export default class CreateInvestorGoalState {
  is_loading = false;

  investor_goal_type = '';

  associated_product_id = '';

  associated_product = '';

  associated_product_contracted = false;

  associated_product_interest_rate = 0;

  investor_goal = {
    id: '',
    customer_id: '',
    goal_name: '',
    desired_amount: 0.0,
    fixed_time_adjusted: 0.0,
    investor_profile_id: '',
    initial_amount: 0.0,
    initial_amount_adjusted: 0.0,
    accumulated_amount: 0.0,
    monthly_required_amount: 0.0,
    final_investment_date: '',
    initial_investment_date: '',
    icon: '',
    defined_by: '',
    wants_years: false,
    already_created: false,
  }

  retirement_fund = {
    issued_age: 0,
    retirement_age_adjusted: 0,
    retirement_range_adjusted: 0,
    pension_range_adjusted: 0,
    lifetime_expected: 85,
    monthly_pension_adjusted: 0,
    calculated_pension: 0,
    already_created: false,
  }

  emergency_fund = {
    months_of_monthly_incomes: 3,
    months_to_reach_goal: 6,
    contribution: '0.0',
    already_created: false,
  }

  custom_investor_goal = {
    months_to_reach_goal: 6,
    contribution: '0.0',
    custom_goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      created_at: '',
      updated_at: '',
    },
    already_created: false,
  }

  is_defined_by_contribution = false;

  wants_years = false;

  goal_created = false;

  goal_created_and_linked = false;

  goal_linked_successfully = false;

  // price by unit wealth
  price_by_unit = 0;

  current_recurrent_contribution = 0;

  define_contribution_later = false;

  recurring_contribution = {
    id: '',
    monthly_collection_day: 0,
    amount: '0',
    periodicity: 'MENSUAL',
    confirm_periodicity: true,
    home_desire: true,
  }

  available_balance = 0;

  recurrent_assigned_amount = 0;

  recurrent_unassigned_contribution = 0;

  total_active_goals_balance = 0;

  unassigned_balance = 0;
}
