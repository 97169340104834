import fixtures from './fixtures.json';
import maximum_initial_amount_fixtures from './maximum-initial-amount.json';
import minimum_accumulated_amount_fixtures from './minimum-accumulated-amount.json';
import maximum_months_fixtures from './maximum-months.json';

// Domain
import { CustomInvestorGoalCalculatorDto }
  from '../../../domain/dtos/custom-investor-goal-calculator-dto';
import {
  CustomGoalMaximumInitialAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-initial-amount-dto';
import {
  CustomGoalMinimumAccumulatedAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-minimum-accumulated-amount-dto';
import { CustomInvestorGoalCalculatorEntity }
  from '../../../domain/entities/custom-investor-goal-calculator-entity';
import {
  CustomGoalMaximumInitialAmountCalculationEntity,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/entities/custom-goal-maximum-initial-amount-calculation-entity';
import {
  CustomGoalMinimumAccumulatedAmountCalculationEntity,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/entities/custom-goal-minimum-accumulated-amount-calculation-entity';
import {
  CustomGoalMaximumMonthsCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-months-dto';
import {
  CustomGoalMaximumMonthsCalculationEntity,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/entities/custom-goal-maximum-months-calculation-entity';
import { CustomInvestorGoalCalculatorRepository }
  from '../../../domain/repositories/custom-investor-goal-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomInvestorGoalCalculatorDummyRepository implements
  CustomInvestorGoalCalculatorRepository {
  getCustomCalculation(custom_calculation_dto: CustomInvestorGoalCalculatorDto):
    Promise<CustomInvestorGoalCalculatorEntity> {
    return Promise.resolve(fixtures);
  }

  calculateMaximumInitialAmount(
    maximum_initial_amount_calculation_dto: CustomGoalMaximumInitialAmountCalculationDto,
  ):
  Promise<CustomGoalMaximumInitialAmountCalculationEntity> {
    return Promise.resolve(maximum_initial_amount_fixtures);
  }

  calculateMinimumAccumulatedAmount(
    minimum_accumulated_amount_calculation_dto: CustomGoalMinimumAccumulatedAmountCalculationDto,
  ):
  Promise<CustomGoalMinimumAccumulatedAmountCalculationEntity> {
    return Promise.resolve(minimum_accumulated_amount_fixtures);
  }

  calculateMaximumInitialMonths():
  Promise<CustomGoalMaximumMonthsCalculationEntity> {
    return Promise.resolve(maximum_months_fixtures);
  }
}
