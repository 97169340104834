
















































































































import { Component, Vue } from 'vue-property-decorator';
import AllianzDashboardViewModel from '@/vue-app/view-models/views/allianz-dashboard-view-model';

@Component({
  name: 'AllianzDashboard',
  components: {
    AccountBalance: () => import('@/vue-app/components/allianz-dashboard/AccountBalance.vue'),
    Performance: () => import('@/vue-app/components/allianz-dashboard/Performance.vue'),
    Movements: () => import('@/vue-app/components/allianz-dashboard/Movements.vue'),
    Position: () => import('@/vue-app/components/allianz-dashboard/Position.vue'),
    Operations: () => import('@/vue-app/components/allianz-dashboard/Operations.vue'),
    Inquiries: () => import('@/vue-app/components/allianz-dashboard/Inquiries.vue'),
    Information: () => import('@/vue-app/components/allianz-dashboard/Information.vue'),
    AllianzContact: () => import('@/vue-app/components/allianz-dashboard/AllianzContact.vue'),
    Extras: () => import('@/vue-app/components/allianz-dashboard/Extras.vue'),
    LinkGoalsCard: () => import('@/vue-app/components/allianz-dashboard/goals/LinkGoalsCard.vue'),
    NavBar: () => import('@/vue-app/components/NavBar.vue'),
    WealthPolicyInProcessOfCancellationBanner: () => import('@/vue-app/components/banners/WealthPolicyInProcessOfCancellationBanner.vue'),
    WealthMoreInvestmentAlternativesBanner: () => import('@/vue-app/components/banners/WealthMoreInvestmentAlternativesBanner.vue'),
    MoreInvestmentAlternativesDialog: () => import('@/vue-app/components/allianz-dashboard/more-investment-alternatives/MoreInvestmentAlternativesDialog.vue'),
    MoreInvestmentAlternativesStepsDialog: () => import('@/vue-app/components/allianz-dashboard/more-investment-alternatives/MoreInvestmentAlternativesStepsDialog.vue'),
    FlagshipProfilingModalSteps: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingModalSteps.vue'),
    FlagshipGoalsModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsModal.vue'),
    FlagshipGoalsWizardModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardModal.vue'),
    FlagshipLinkGoalsWizardWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/FlagshipLinkGoalsWizardWealth.vue'),
    AccessMoreStrategiesDialog: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategiesDialog.vue'),
    AccessMoreStrategiesModerateHiringDialog: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategiesModerateHiringDialog.vue'),
    AccessMoreStrategySuccessfullyDialog: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategySuccessfullyDialog.vue'),
    RequestsDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/RequestsDialog.vue'),
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      const has_account = await vm.$data.allianz_dashboard_view_model.validateAccount();
      if (!has_account) {
        vm.$router.push({ name: 'HomeDashboard' });
      }
    });
  },
})
export default class AllianzDashboard extends Vue {
  allianz_dashboard_view_model = Vue.observable(new AllianzDashboardViewModel());

  created() {
    if (this.$route?.query?.hasOwnProperty('show_pending_transactions')) {
      this.allianz_dashboard_view_model.showPendingRequests();
    }
    this.allianz_dashboard_view_model.initialize();
  }
}

