import TYPES from '../../types';

// Domain
import {
  LevelOfEducationRepository,
} from '@/modules/flagship/catalogs/level-of-education/domain/repositories/level-of-education-repository';
import {
  LevelOfEducationEntity,
} from '@/modules/flagship/catalogs/level-of-education/domain/entities/level-of-education-entity';
import {
  LevelOfEducationStateManager,
} from '@/modules/flagship/catalogs/level-of-education/domain/state/level-of-education-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetLevelOfEducationQuery
  extends Query<Promise<Array<LevelOfEducationEntity>>> {
  @Inject(TYPES.LEVEL_OF_EDUCATION_REPOSITORY)
  private readonly repository!: LevelOfEducationRepository;

  @Inject(TYPES.LEVEL_OF_EDUCATION_STATE_MANAGER)
  private readonly state_manager!: LevelOfEducationStateManager;

  internalExecute(): Promise<Array<LevelOfEducationEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
